import React from 'react';
import Layout from '../layouts/page-layout';
import SEO from '../utils/seo';
import IndustryContainer from '../containers/industryContainer';

const IndustriesPage = () => {
  return (
    <Layout fixed={true}>
      <SEO
        title="Industries"
        description="Find out how termites and pests affect your particular business or situation and see exactly how Unitech Pest and Termite Control can help get rid of what's bugging you! Free inspection on us!"
      />
      <IndustryContainer />
    </Layout>
  );
};
export default IndustriesPage;
